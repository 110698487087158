/** ******************************************************************************
Environment Variables
 * All environment variables injected at build time should be collected here.
 ******************************************************************************* */

/** the curent stack ("enviroment", e.g. dev, prod) */
export const stack = process.env.NEXT_PUBLIC_STACK;

export const env = {
  isProduction: stack === "prod",
};

export const gexaEnabled = process.env.NEXT_PUBLIC_GEXA === "on";
